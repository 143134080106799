<template>
  <div id="app">
    <div class="vip-container">
      <div class="vip-info">
        <div class="title">加入AI降临派VIP</div>
        <div class="desc">和我们一起探索AI的技术边界</div>
        <!-- <div class="date">
          <span class="oplist" @click="gotoOrderList" v-if="!isIOS">订单记录></span>
          <span v-if="isVIP">会员有效期至: {{ expireTime ? dateFormat(expireTime, 'yyyy-MM-dd') : '-'}}</span>
        </div> -->
      </div>
			<!-- <div>{{chatToken}}</div>
			<div>{{step}}</div>
			<div>{{error}}</div> -->
			<div class="vip-info vip-rights">
				<div class="title-view">
					<div class="tabitem" :class="{active: ctype == 1}" @click="ctype = 1">
						VIP会员权益
					</div>
					<div class="tabitem" :class="{active: ctype == 2}" @click="ctype = 2">
						AI能量包
					</div>
				</div>
				<div class="recharge-types">
					<div class="type-item" v-for="(item, index) in typelist" :class="{active: curIndex === index}" 
					:key="index" @click="changeChargeType(index)">
						<div class="p1">{{ item.typename }}</div>
						<div class="p-now">{{ item.price }}</div>
						<div class="p-op">{{ item.op }}</div>
						<div class="p2" :class="{needScale: ctype == 1}">{{ item.desc }}</div>
					</div>
				</div>
				<div class="list" v-if="ctype == 1">
					<div class="list-item" v-for="(item, index) in list" :key="index">
						{{ item }}
					</div>
				</div>
				<div class="list" v-if="ctype == 2">
					<div class="list-item" v-for="(item, index) in list2" :key="index">
						{{ item }}
					</div>
				</div>
				
			</div>
			<div class="pay-view">
				<div class="pay-btn" @click="handlePay">
					支付
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { queryToObj } from './utils/index.js'
const PAY_TYPE = {
	MONTHLY: 'MONTHLY', //包月
	SEASONALLY: 'SEASONALLY', //包季;
	YEARLY: 'YEARLY' ,// 包年
	S_PLUS: 'S_PLUS',
	M_PLUS: 'M_PLUS',
	L_PLUS: 'L_PLUS'
}
const baseurl = 'https://api.aibaidu.com.cn/'
let chatSeq = 1000000
export default {
  name: 'App',
  data() {
    return {
			chatToken: '',
      ctype: 1,
			list2: [
				'1、购买AI能量包立刻获得算力。',
				'2、多个AI能量包可重复可叠加购买，算力永不过期。'
			],
			curIndex: 0,
			vipTypes: [
				{ type: PAY_TYPE.MONTHLY, typename: '1个月VIP',  op: '￥30', desc: '给程序员买杯咖啡'},
				{ type: PAY_TYPE.SEASONALLY, typename: '3个月VIP',  op: '￥90', desc: '请程序员喝个小酒'},
				{ type: PAY_TYPE.YEARLY, typename: '12个月VIP', op: '￥360', desc: '请程序员吃顿大餐'}
			],
			timesTypes: [
				{ type: PAY_TYPE.S_PLUS, typename: '小能量包',  op: '￥15', desc: '200点算力'},
				{ type: PAY_TYPE.M_PLUS, typename: '中能量包',  op: '￥30', desc: '400点算力'},
				{ type: PAY_TYPE.L_PLUS, typename: '大能量包',  op: '￥45', desc: '600点算力'}
			],
			orderList: [],
			page: 1,
			step: 1,
			error: ''
    }
  },
  created() {
    const search = window.location.search
    const obj = queryToObj(search.substring(1))
		this.chatToken = obj.chatToken
		this.getProducts()
  },
  computed: {
    typelist() {
      if (this.ctype == 1) {
        return this.vipTypes
      } else if (this.ctype == 2) {
        return this.timesTypes
      } else {
        return []
      }
    },
    list() {
				const arr1 = [300, 1000, 5000]
				const arr2 = [10, 30, 120]
				return [
					`1、立即获得${arr1[this.curIndex]}点算力。`, 
					`2、立即获得${arr2[this.curIndex]}次AI绘画插队权益。`,
					'3、每日签到领取20点算力，每月最多可领600点算力。',
					'4、每日签到领取2次AI绘画插队权益，每月最多可领60次绘画插队权益。',
					'5、在高峰时段获得AI智能问答优先回复，使用更顺畅。'
				]
			}
  },
  methods: {
		
		changeChargeType(index) {
			this.curIndex = index
		},
    async handlePay() {
			const type = this.typelist[this.curIndex].id
			const {data: res} = await axios({
					url: baseurl + 'wx-mp/preOrder',
					method: 'POST',
					headers: {
						'content-type': 'application/x-www-form-urlencoded',
						'chat-token': this.chatToken,
						'chat-seq': chatSeq++
					},
					data: {
						type: type
					}
			}) 
			this.step = 2
			if (res.code === 0) {
				// eslint-disable-next-line
				this.payObj = res.data
				if (typeof WeixinJSBridge == "undefined") {
					this.error = 'WeixinJSBridge is undefined'
					if (document.addEventListener) {
						document.addEventListener(
							"WeixinJSBridgeReady",
							this.onBridgeReady,
							false
						);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
						document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
					}
				} else {
					this.onBridgeReady();
				}

			}
    },

		onBridgeReady() {
			
			this.step = 3
			const that = this
			// eslint-disable-next-line
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: this.payObj.appId, //公众号ID，由商户传入
          timeStamp: this.payObj.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: this.payObj.nonceStr, //随机串
          package: this.payObj.packageVal,
          signType: this.payObj.signType, //微信签名方式：
          paySign: this.payObj.paySign, //微信签名
        },
        function (res) {
					that.error = JSON.stringify(res)
					that.step = 4
					console.log('支付结果：', res)
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            alert("支付成功");
          }
          that.closePage();
        }
      );
    },
		
		async getProducts() {
			const {data: res} = await axios({
					url: baseurl + 'user/products',
					method: 'GET',
					headers: {
						'content-type': 'application/x-www-form-urlencoded',
						'chat-token': this.chatToken,
						'chat-seq': chatSeq++
					}
			}) 
			if (res.code === 0) {
				const vips = []
				res.data.vip.map((m, index) => {
					const price = `￥${parseFloat((m.amount / 100).toFixed(2))}` 
					vips[index] = {...this.vipTypes[index], ...m, price}
				})
				this.vipTypes = vips;
				const plus = []
				res.data.plus.map((m, index) => {
					const price = `￥${parseFloat((m.amount / 100).toFixed(2))}` 
					plus[index] = {...this.timesTypes[index], ...m, price}
				})
				this.timesTypes = plus
			}
		},
		closePage() {
      var userAgent = navigator.userAgent;
      if (
        userAgent.indexOf("Firefox") != -1 ||
        userAgent.indexOf("Chrome") != -1
      ) {
        // Firefox或Chrome中关闭

        window.location.href = "about:blank";
      } else {
        window.opener = null;

        window.open("", "_self");

        window.close();
      }
			// eslint-disable-next-line
      if (WeixinJSBridge) {
        // 微信中关闭
				// eslint-disable-next-line
        wx.closeWindow();
      }
		}
  }
}
</script>

<style lang="less" scoped>
// @import url('./assets/mixin.less');

.combtn(@fs: 28rpx, @lineHeight: 60rpx) {
	font-size: @fs;
	line-height: @lineHeight;
	background-color: @mainColor;
	border: none;
	color: #FFF;
	text-align: center;
	border-radius: 8rpx;
	&:active {
		background-color: lighten(@mainColor, 5%);
	}
}


#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #EFEFEF;
  /* margin-top: 60px; */
  background-color: #33373f;
  height: 100vh;
}

.vip-container {
	padding: 20px;
	background-color: @mainBg;
	height: 100%;
	color: #EDEDED;
	
	.iosview {
		margin-top: 15px;
		color: @fontColor;
	}
	.btnview {
		margin-top: 150px;
		padding: 30px;
		.subbtn {
			.combtn(16px, 50px);
		}
	}
	
	.vip-info {
		padding: 10px 15px;
		background-color: lighten(@mainBg, 10%);
		border-radius: 6px;
		.title {
			color: @mainColor;
			font-size: 18px;
			font-weight: 600;
		}
		.desc {
			font-size: 14px;
			padding-top: 2px;
		}
		.date {
			padding-top: 12px;
			display: flex;
			justify-content: space-between;
			.oplist {
				color: @mainColor;
			}
		}
	}
	
	.vip-rights {
		margin-top: 15px;
		padding-bottom: 20px;
		height: 400px;
		box-sizing: border-box;
		
		.title-view {
			display: flex;
			.tabitem {
				line-height: 36px;
				flex: 1;
				color: @fontColor;
				font-size: 16px;
				font-weight: 600;
				text-align: center;
				position: relative;
				&.active {
					color: @mainColor;
				}
				&:last-child::after {
					content: "";
					height: 14px;
					left: 0;
					top: 11px;
					width: 1px;
					background-color: #EDEDED;
					position: absolute;
				}
			}
		}
		.list {
			padding: 10px 0;
			.list-item {
				padding: 2px 0;
			}
		}
		.recharge-types {
			padding-top: 12px;
			display: flex;
			.type-item {
				flex: 1;
				border: 1px solid @mainColor;
				background-color: transparent;
				border-radius: 4px;
				text-align: center;
				font-size: 12px;
				padding: 10px 0;
				// font-weight: 600;
				&:not(:last-child) {
					margin-right: 10px;
				}
				&.active {
					color: #fff;
					background-color: #32715f;
				}
				.p1 {
					font-size: 14px;
				}
				.p-now {
					font-weight: 600;
					font-size: 24px;
					line-height: 50px;
				}
				.p-op {
					font-size: 14px;
					text-decoration: line-through;
				}
				.p2.needScale {
					line-height: 20px;
					transform: scale(0.85);
					word-break: keep-all;
				}
			}
		}
	}
	
	.pay-view {
		position: fixed;
		padding: 10px 0;
		bottom: 40px;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
	
		.pay-btn {
			width: 160px;
			line-height: 40px;
			text-align: center;
			background-color: @mainColor;
			border-radius: 4px;
			color: #fff;
			font-size: 16px;
		}
		.paydesc {
			text-align: center;
			font-size: 12px;
			padding: 5px 0;
		}
	}
	
}
</style>
